.svg-wrapper {
    overflow: scroll;
}
.node-info-wrapper {
    position: relative;
}
.node-info-wrapper {
    width: 100%;
    height: 90px;
}
.node-info {
    height: 100%;
}
.node-name {
    color: #555;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1em;
}
.node-mouse-over {
    position: absolute;
    padding: 5px;
    top: 0px;
    left: 0px;
    height: 100%;
    flex-flow: row;
    width: 100%;    
    display: none;
    background-color: #DDD; 
    justify-content: space-evenly;
    align-items: center;
}

.node-info-wrapper:hover .node-mouse-over {
    display: flex;
}

.node-mouse-over .node-mouse-over-elem {
    cursor: pointer;
}

.material-symbols-outlined {
    font-size: 1.9rem !important;
}