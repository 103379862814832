.iq-footer {
    background-color: $card-bg !important;
    ul {
        li {
            a {
                color: $body-color;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
}